import "bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import "font-awesome/css/font-awesome.css";

$(function () {
  $(document).on(
    "click",
    'input[name="invitation[venue_how_to_hold]"]',
    function () {
      if (this.id === "invitation_venue_how_to_hold_select") {
        $(".js-venue-which-free").css("display", "none");
        $(".js-venue-which-select").show();
      } else if (this.id === "invitation_venue_how_to_hold_free") {
        $(".js-venue-which-select").css("display", "none");
        $(".js-venue-which-free").show();
      }

      google.maps.event.trigger(window.mapResult.googleMap, "resize");
      const center = new google.maps.LatLng(
        $("#free_writing_venue_lat").val(),
        $("#free_writing_venue_lng").val(),
        false
      );
      window.mapResult.googleMap.setCenter(center);
    }
  );

  $("#upload-cover-photo-link").on("click", function (e) {
    e.preventDefault();
    $("#new_cover_photo_file").click();
  });

  $("#new_cover_photo_file").on("change", function () {
    this.form.submit();
  });

  $(".edit_cover_photo input:file").on("change", function () {
    this.form.submit();
  });

  $(document).on("click", ".js-preview-via-post-method", function () {
    const method = $(this).attr("data-method");
    const action = $(this).attr("data-action");
    const target = $(this).attr("data-target");
    const form_id = $(this).attr("data-formid");

    const form = $(form_id);
    const _method = $(form_id + ' input[name="_method"]');

    const originalMethod = form.attr("method");
    const originalAction = form.attr("action");
    const original_Method = _method.val();

    form.attr("method", method);
    form.attr("action", action);
    form.attr("target", target);
    _method.val(method);

    form.submit();

    form.attr("method", originalMethod);
    form.attr("action", originalAction);
    form.removeAttr("target");
    _method.val(original_Method);

    return false;
  });

  $(".js-show-selected-filename").on("change", function (e) {
    const files = e.target.files;
    const target = e.target.dataset["target"];

    if (files.length !== 0) {
      $(target).html(
        '<p class="mb-20">選択中のファイル: ' + files[0].name + "</p>"
      );
    } else {
      $(target).empty();
    }
  });

  $(".js-show-selected-image-thumbnail").on("change", function (e) {
    const files = e.target.files;
    const target = document.querySelector(e.target.dataset["targetImg"]);

    if (files.length !== 0) {
      target.src = window.URL.createObjectURL(files[0]);
      $(target).on("load", function () {
        window.URL.revokeObjectURL(this.src);
      });
    }
  });

  if ($("#kind_venue").prop("checked")) {
    $("#venue_id").prop("disabled", false).show();
    $("#wedding_hall_id").prop("disabled", true).hide();
  } else {
    $("#wedding_hall_id").prop("disabled", false).show();
    $("#venue_id").prop("disabled", true).hide();
  }

  $("#kind_venue, #kind_wedding_hall").on("click", function (e) {
    if (e.target.value === "venue") {
      $("#venue_id").prop("disabled", false).show();
      $("#wedding_hall_id").prop("disabled", true).hide();
    } else {
      $("#wedding_hall_id").prop("disabled", false).show();
      $("#venue_id").prop("disabled", true).hide();
    }
  });

  $("#new_party #user_email").on("focusout", function (e) {
    if (!$("#user_email").get(0).checkValidity()) {
      $("#user_name").val("");

      $(".js-registered-message").hide();
      $(".js-unregistered-message").hide();

      $(".js-password-regeneration-checkbox").hide();
      $(".js-password-regeneration-checkbox input").prop("disabled", true);

      return false;
    }

    const email = e.target.value;

    $.ajax("/admin/users?email=" + email).done(function (response) {
      if (response.length > 0) {
        $("#user_name").val(response[0].name);

        $(".js-registered-message").show();
        $(".js-unregistered-message").hide();

        $(".js-password-regeneration-checkbox").show();
        $(".js-password-regeneration-checkbox input").prop("disabled", false);
      } else {
        $("#user_name").val("");

        $(".js-registered-message").hide();
        $(".js-unregistered-message").show();

        $(".js-password-regeneration-checkbox").hide();
        $(".js-password-regeneration-checkbox input").prop("disabled", true);
      }
    });
  });

  $("#js-fee-checkbox").on("change", function (e) {
    if (e.target.checked) {
      $("#js-fee-input").show();
    } else {
      $("#js-fee-input").hide();
    }
  });
});

(function ($) {
  /**
   * ユーザーエージェント
   */
  const _ua = (function (u) {
    return {
      Tablet:
        (u.indexOf("windows") !== -1 && u.indexOf("touch") !== -1) ||
        u.indexOf("ipad") !== -1 ||
        (u.indexOf("android") !== -1 && u.indexOf("mobile") === -1) ||
        (u.indexOf("firefox") !== -1 && u.indexOf("tablet") !== -1) ||
        u.indexOf("kindle") !== -1 ||
        u.indexOf("silk") !== -1 ||
        u.indexOf("playbook") !== -1,
      Mobile:
        (u.indexOf("windows") !== -1 && u.indexOf("phone") !== -1) ||
        u.indexOf("iphone") !== -1 ||
        u.indexOf("ipod") !== -1 ||
        (u.indexOf("android") !== -1 && u.indexOf("mobile") !== -1) ||
        (u.indexOf("firefox") !== -1 && u.indexOf("mobile") !== -1) ||
        u.indexOf("blackberry") !== -1,
    };
  })(window.navigator.userAgent.toLowerCase());

  /**
   * htmlタグに各クラスを付与
   */
  function augent() {
    const $html = $("html");

    if ($html.hasClass("no-js")) {
      $html.toggleClass("no-js");
    }

    if (_ua.Mobile) {
      // モバイル時
      if (!$html.hasClass("mobile")) {
        $html.toggleClass("mobile");
      }
    } else if (_ua.Tablet) {
      // タブレット時
      if (!$html.hasClass("tablet")) {
        $html.toggleClass("tablet");
      }
    } else {
      // PC時
      if (!$html.hasClass("pc")) {
        $html.toggleClass("pc");
      }
    }
  }
  augent();

  /**
   * ナビゲーションの開閉ボタン処理
   */
  $('[data-toggle="side"]').on("click", function () {
    $("#side, #navbar-menu").toggleClass("active");
  });
})(jQuery);
